import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import MicroModal from 'micromodal';
import Link from 'next/link';
import { useRouter } from 'next-translate-routes';

// Utils
import { cn } from 'utils';
import getYoutubeVideoId from '../../../src/utils/getYoutubeVideoId';

// Styles
import styles from './HeroSectionBlock.module.css';

// Components
import YoutubePlayer from '../../atoms/YoutubePlayer/YoutubePlayer';
// import SearchBar from '../../molecules/SearchBar/SearchBar';
import Cursor from '../../atoms/Cursor/Cursor';
import useCursor from '../../atoms/Cursor/useCursor';
import RichTextContent from '../../atoms/RichTextContent/RichTextContent';
// Constants
import { URL_SEARCH_PAGES } from '../../../src/constants';

const Modal = dynamic(() => import('../Modal/Modal'), {
  ssr: false,
});

function HeroSectionBlock({
  title,
  content,
  media,
  youtube,
  // label,
  // cities,
  // regions,
  // departments,
}) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [youtubeIsDisplay, setYoutubeIsDisplay] = useState(false);
  const { locale } = useRouter();
  /**
   * Handle Modal Youtube
   * @returns {void}
   */
  const handleModalYoutube = () => {
    if (!youtube) return;
    MicroModal.show('HeroYoutubeVideo', {
      onShow: () => {
        setYoutubeIsDisplay(true);
      },
      onClose: () => {
        setTimeout(() => {
          setYoutubeIsDisplay(false);
        }, 300);
      },
    });
  };

  const {
    textEnter, textLeave, variants, cursorVariant,
  } = useCursor(ref, handleModalYoutube);

  return (
    <>
      <div
        className={styles.container}
      >
        <div className={styles.titleContainer}>
          <div className={styles.content}>
            <h1 className={styles.title}>
              {title}
            </h1>
            <RichTextContent content={content} />
          </div>
          {/* <SearchBar
            cities={cities}
            regions={regions}
            departments={departments}
            label={label}
            className={styles.searchBar}
            defaultWidth="732px"
          /> */}
          <div className={cn([styles.link, 'd-flex padding-left-80 margin-top-24'])}>
            <Link className="linkButton" href={URL_SEARCH_PAGES[locale]}>
              {t('heroSectionBlock.linkLabel')}
            </Link>
          </div>
          <div className={cn([styles.heroImg, styles.isMobile])}>
            {media?.url ? (
              <Image
                priority
                src={media.url}
                alt={media?.description}
                fill
                sizes="100vw"
              />
            ) : null}
          </div>
        </div>
        <div
          className={styles.heroContainer}
        >
          <div
            className={cn([styles.heroImg, youtube ? styles.hasYoutube : ''])}
            ref={ref}
          >
            {media?.url ? (
              <Image
                priority
                src={media.url}
                alt={media?.description}
                fill
                onMouseEnter={textEnter}
                onMouseLeave={textLeave}
                sizes="(max-width: 768px) 100vw,
                                           50vw"
              />
            ) : null}
            {youtube
              ? (
                <Cursor
                  variants={variants}
                  cursorVariant={cursorVariant}
                  label={t('heroSectionBlock.watchVideo')}
                  onClick={handleModalYoutube}
                />
              )
              : null}
          </div>
        </div>
      </div>
      {youtube ? (
        <Modal
          id="HeroYoutubeVideo"
          className={styles.modal}
          hideCancelButton
          maxWidth={1000}
        >
          {youtubeIsDisplay ? (
            <YoutubePlayer videoId={getYoutubeVideoId(youtube)} />
          ) : null}
        </Modal>
      ) : null}
    </>
  );
}

HeroSectionBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  // label: PropTypes.string,
  media: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  // cities: PropTypes.arrayOf(PropTypes.shape({
  //   data: PropTypes.shape({
  //     city: PropTypes.arrayOf(PropTypes.shape({})),
  //   }),
  // })),
  // regions: PropTypes.arrayOf(PropTypes.shape({
  //   data: PropTypes.shape({
  //     region: PropTypes.arrayOf(PropTypes.shape({})),
  //   }),
  // })),
  // departments: PropTypes.arrayOf(PropTypes.shape({
  //   data: PropTypes.shape({
  //     department: PropTypes.arrayOf(PropTypes.shape({})),
  //   }),
  // })),
  youtube: PropTypes.string,
};

HeroSectionBlock.defaultProps = {
  title: '',
  content: '',
  // label: '',
  media: {
    url: '',
    description: '',
  },
  // cities: [],
  // regions: [],
  // departments: [],
  youtube: '',
};

export default HeroSectionBlock;
